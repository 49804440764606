import React from "react";
import ReactDOM from "react-dom";

import Preloader from "./components/Preloader/Preloader";
import Timer from "./components/Countdown/Timer";
import Optin from "./components/Optin/Optin";

import "./index.css";

function App() {
  return (
    <div className="App">
      <div className="container">
        <img className="logoimag"  src='computercity.png' alt="Computer City, LDA"/>
        <h1> Computer City, LDA </h1>
        <h2> Em  Breve</h2>
        <Timer />
        <Optin />
        <Preloader />
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);